<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mt-8">
        <vx-card>
           <div class="card-title">
               <h2 class="w-auto my-2">Filter Zip Codes</h2>
               <div class="d-flex gap-2">
               <vs-button
                  title="Add Zip Codes"
                  @click="addZipcodesActive=true, addEditZipcodesTitle = 'Add Zip Codes', editableZipcode = false"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="sm:mt-0 mt-2 mr-2"
                  >Add Zip Codes</vs-button>
                <vs-button
                  color="grey"
                  type="border"
                  class="grey-btn sm:mt-0 mt-2"
                  @click="clearListActive = true"
                >Clear List</vs-button>
              </div>
            </div>
            <div class="common-table">
                  <div class="table-row-select">
                     <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                     <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in recordList"
                     />
                     </vs-select>
                  </div>
                  <vs-table :data="filterZipcodesList" ref="tableData" :max-items="perPage" pagination search>
                     <template slot="thead">
                     <vs-th>Options</vs-th>
                     <vs-th sort-key="Zip_Code">Included Zip Codes</vs-th>
                     </template>
                     <template slot-scope="{data}">
                     <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                           <div class="table-action">
                           <vs-button
                              title="Edit"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-edit"
                              class="mr-2 float-left"
                              color="primary"
                              @click="filterZipcodeId = tr.Id, Zipcode = tr.Zip_Code, addEditZipcodesTitle = 'Edit Zip Codes', editableZipcode = true, addZipcodesActive = true"
                           ></vs-button>
                           <vs-button
                              title="Delete"
                              @click="deleteZipcodesConfirm(tr)"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-trash-2"
                              class="mr-0"
                           ></vs-button>
                           </div>
                        </vs-td>
                        <vs-td :data="tr.Zip_Code">{{tr.Zip_Code}}</vs-td>
                     </vs-tr>
                     </template>
                  </vs-table>
                  <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{filterZipcodesList.length|tableRecordNumberFormatter}} entries</p>
                  <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{filterZipcodesList.length|tableRecordNumberFormatter}} entries)</p>
                  <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
            </div>

        </vx-card>
      </div>
    </div>
    <!-- Add New Domain Modal -->
    <vs-popup class="tag-info" :title="addEditZipcodesTitle" :active.sync="addZipcodesActive">
        <template>
          <div class="vx-row">
            <div class="vx-col mb-2 w-full">
              <p v-if="!editableZipcode" class="mb-5">When you add zip code, the system will only add contacts when a visitor had a match. All entries will be added ad "Exact" match</p>
              <p v-if="!editableZipcode" class="w-full float-left pl-2"> Please enter Zip Codes one per line </p>
              <p v-else class="pl-1">Zip Code</p>
              <div class="vx-row align-center ">
                <div class="vx-col mb-0 w-full pl-5">
                  <vs-textarea v-if="!editableZipcode" class="w-full mb-4" v-model="Zipcode"> </vs-textarea>
                  <vs-input v-else class="w-full mb-4" type="text" v-model="Zipcode"></vs-input>
                </div>
              </div>
            </div>
          </div>
      </template>
        <div class="float-right mt-6 mb-8">
          <vs-button @click="addZipcodes()" :disabled="!Zipcode" type="filled" button="submit" color="primary">
            {{ editableZipcode ? 'Update Zip Code' : 'Add Zip Codes' }}
          </vs-button>
          <vs-button @click="cancelZipcode()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Delete Zip Code Modal -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Zip Code?</h4>
            <span class="deleted-no">{{ Zipcode?Zipcode:""}}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteZipcode()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          @click="deletepopupActive=false"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>

    <!-- Clear List Modal -->
    <vs-popup class="delete-popup" :active.sync="clearListActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Clear List?</h4>
            <span class="deleted-no">Clicking on this will remove all Zip Codes from the list below.  Do you want to continue?</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="clearZipcode()">Yes</vs-button>
        <vs-button
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          @click="clearListActive=false"
        >No</vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import VxCard from "@/components/vx-card/VxCard";
export default {
  components: {
    VxCard,
  },
  data() {
    return {
      clearListActive: false,
      editableZipcode: false,
      addEditZipcodesTitle: 'Add Zip Codes',
      Zipcode: null,
      filterZipcodeId: null,
      addZipcodesActive: false,
      deletepopupActive: false,
      filterZipcodesList: [],
      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    };
  },
  mounted() {
    this.getFilterZipCodes();
  },
  methods: {
    cancelZipcode() {
      this.filterZipcodeId = null;
      this.Zipcode = null;
      this.addZipcodesActive = false;
    },
    async getFilterZipCodes() {
      this.$vs.loading();
      await this.axios
        .get("/ws/ConfigFilterZipCodes/GetFilterZipCodes")
        .then(response => {
          let data = response.data;
          this.filterZipcodesList = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async addZipcodes() {
      this.$vs.loading();
      let payload
      if(!this.editableZipcode){
          payload = this.Zipcode.split("\n");
      }else{
        payload = {
            "Id": this.filterZipcodeId,
            "Zip_Code": this.Zipcode
          }
      }
      let url = !this.editableZipcode
        ? "/ws/ConfigFilterZipCodes/AddNewFilterZipCodes"
        : "/ws/ConfigFilterZipCodes/UpdateFilterZipCodes";
      await this.axios
        .post(url, payload)
        .then(() => {
          this.Zipcode = null;
          this.filterZipcodeId =null;
          this.addZipcodesActive = false;
          this.getFilterZipCodes();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async deleteZipcodesConfirm(data) {
      this.Zipcode = data.Zip_Code;
      this.filterZipcodeId = data.Id;
      this.deletepopupActive = true;
    },
    async deleteZipcode() {
      this.$vs.loading();
      await this.axios
        .get("/ws/ConfigFilterZipCodes/DeleteFilterZipCodes?id=" + this.filterZipcodeId)
        .then(() => {
          this.Zipcode = null;
          this.filterZipcodeId = null;
          this.deletepopupActive = false;
          this.getFilterZipCodes();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async clearZipcode(){
      this.$vs.loading();
      await this.axios.get("/ws/ConfigFilterZipCodes/ClearFilterZipCodes").then(() => {
        this.clearListActive = false;
        this.getFilterZipCodes();
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    }
  },
  computed: {
		isTableRecordEntriesDisplay(){
			if(this.filterZipcodesList.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
        if(this.filterZipcodesList.length > 0){
            if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
                return false
            }else{
                return true
            }
        }
    },
    showingDataFrom: function () {
        if(this.filterZipcodesList.length > 0){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1
        }
    },
    showingDataTo: function () {
        if(this.filterZipcodesList.length > 0){
            let totalPages = this.$refs.tableData.getTotalPages
            if(totalPages == this.$refs.tableData.currentx){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
            }else{
                return ((this.$refs.tableData.currentx) * this.perPage)
            }
        }
    },
    totalSearchedData: function () {
        if(this.filterZipcodesList.length > 0){
            return this.$refs.tableData.queriedResults.length
        }
    },
    showingSearchedDataTo: function () {
        if(this.filterZipcodesList.length > 0){
            let totalPages = this.$refs.tableData.getTotalPagesSearch
            if(totalPages == this.$refs.tableData.currentx){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
            }else{
                return ((this.$refs.tableData.currentx) * this.perPage)
            }
        }
    }
  }
};
</script>
